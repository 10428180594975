import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import React from 'react'; 
const NewsSlider = ({ contents }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Slider
        {...settings}
        className="slider-content btn-style-1"
        style={{ border: "none" }}
      >
        {contents.map((content) => (
          <div>
            <div className="news">
              <div className="news-image">
                <GatsbyImage image={getImage(content.thumbnail)} />
              </div>
              <div className="news-content">
                <h4 className="news-date">{content.date}</h4>
                <h4 className="news-title">{content.title}</h4>
                <Link to={`/News/${content.slug}`}>
                  <button className="news-button">Read More</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default NewsSlider
