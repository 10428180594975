import React from 'react'

const Quotes = () => {
  return (
    <section
      className="elementor-element elementor-element-df31f43 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="df31f43"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-87f09a8 elementor-column elementor-col-100 elementor-top-column"
            data-id="87f09a8"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-c60229b elementor-widget__width-inherit elementor-absolute elementor-widget elementor--widget--image"
                  data-id="c60229b"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={747}
												height={704}
												src={img01}
												className="attachment-full size-full"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-26845b4 elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                  data-id="26845b4"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2
                      className="elementor-heading-title elementor-size-default"
                      id="quotes-font-size"
                    >
                      <br />
                      Pharma Metric Lab is the leading CRO that will give you
                    </h2>
                    <h2
                      className="elementor-heading-title elementor-size-default"
                      id="quotes-font-size"
                    >
                      better solution, experience, and result
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Quotes
